import './AddSchedule.scss';
import React, { useState } from 'react';

const AddSchedule = ({ field, addScheduleToField }) => {
    // Initialize component state variables
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedZoneId, setSelectedZoneId] = useState(null); // State to store the ID of the selected zone
    const [selectedZones, setSelectedZones] = useState([]);
    const [sequenceNumber, setSequenceNumber] = useState(1);
    const [duration, setDuration] = useState({ hours: '', minutes: '' });
    const [nextStartTime, setNextStartTime] = useState('');
    const [sequence, setSequence] = useState([]);
    const [scheduleName, setScheduleName] = useState('');
    const [schedule, setSchedule] = useState({});

    // Handler for when a zone is selected
    const handleZoneClick = (zoneName, zoneId) => {
        setSelectedZone(zoneName);
        setSelectedZoneId(zoneId); // Set the ID of the selected zone
    };

    // Handler for adding a zone to the selected zones
    const handleAddClick = () => {
        if (selectedZone) {
            const zoneData = {
                zone: selectedZone,
                zoneId: selectedZoneId, // Include the ID of the selected zone
                duration,
            };
            setSelectedZones([...selectedZones, zoneData]);
            setSequence([...sequence, zoneData]);
            setSelectedZone(null);
            setSelectedZoneId(null);
            setDuration({ hours: '', minutes: '' });
            setNextStartTime('');
        }
        setSequenceNumber(sequenceNumber + 1);
    };

    // Handler for changing the duration (hours and minutes)
    const handleDurationChange = (e) => {
        const { name, value } = e.target;
        setDuration({ ...duration, [name]: value });
    };

    // Handler for changing the next start time
    const handleNextStartTimeChange = (e) => {
        setNextStartTime(e.target.value);
    };

    // Handler for creating and adding a schedule
    const handleCreateSchedule = () => {
        // Create the schedule object
        const newSchedule = {
            scheduleName,
            nextStartTime,
            sequence,
        };
        // Set the schedule state variable with the new schedule object
        addScheduleToField(field.controllerId, newSchedule);
        setSchedule(newSchedule);
    };

    // Handler for changing the schedule name
    const handleScheduleNameChange = (e) => {
        setScheduleName(e.target.value);
    };

    return (
        <div className='AddSchedule'>
            <h1>Create Schedule: {field.name}</h1>
            <label htmlFor="nameOfSchedule">Add Schedule Name</label>
            <input
                type="text"
                id="scheduleName"
                name="scheduleName"
                value={scheduleName}
                onChange={handleScheduleNameChange}
            />
            <div className='options-container'>
                <div className="zone-container">
                    <h2> Select Zone</h2>
                    <div className='zone-box'>
                        {field.zones &&
                            field.zones.map((zone, index) => (
                                <div
                                    key={index}
                                    className={`zone-options ${zone.name === selectedZone ? 'selected' : ''}`}
                                    onClick={() => handleZoneClick(zone.name, zone.id)}
                                >
                                    {zone.name}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='add-container'>
                    <div className='duration-container'>
                        <label htmlFor="hours">Duration (hours):</label>
                        <input
                            type="number"
                            id="hours"
                            name="hours"
                            value={duration.hours}
                            onChange={handleDurationChange}
                            placeholder="Hours"
                        />
                        <label htmlFor="minutes">Duration (minutes):</label>
                        <input
                            type="number"
                            id="minutes"
                            name="minutes"
                            value={duration.minutes}
                            onChange={handleDurationChange}
                            placeholder="Minutes"
                        />
                    </div>
                    <button onClick={handleAddClick}>Add Zone</button>
                </div>
                <div className='sequence-container'>
                    <h2>Sequence</h2>
                    <div className='sequence-box'>
                        {selectedZones.map((zoneData, index) => (
                            <div key={index} className='sequence-item'>
                                {1 + index}. {zoneData.zone} ({zoneData.duration.hours}h {zoneData.duration.minutes}m)
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='datetime-container'>
                <label htmlFor="nextStartTime">Assign Next Start Time:</label>
                <input
                    type="datetime-local"
                    id="nextStartTime"
                    name="nextStartTime"
                    value={nextStartTime}
                    onChange={handleNextStartTimeChange}
                />
            </div>
            <button onClick={handleCreateSchedule}>Create Schedule</button>
        </div>
    );
};

export default AddSchedule;

