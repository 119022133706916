import { URL } from "./URLVariables";


export const fetchData = (reqType, token, id) => {

    if (reqType === 'fieldnames') {
        return fetch(`${URL}/api/field`, {
            headers: {
                'Authorization': `sessionid ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 404) {
                    throw new Error('404 Page not found');
                } else {
                    throw new Error(`${response.status}`);
                }
            })
            .catch(error => {
                throw new Error(`: ${error.message}`);
            });
    } else if (reqType === 'singleField') {
        return fetch(`${URL}/api/field/${id}`, {
            headers: {
                'Authorization': `sessionid ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 404) {
                    throw new Error('404 Page not found');
                } else {
                    throw new Error(`${response.status}`);
                }
            })
            .catch(error => {
                throw new Error(`: ${error.message}`);
            });
    } else if (reqType === 'allZones') {
        return fetch(`${URL}/api/zone`, {
            headers: {
                'Authorization': `sessionid ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 404) {
                    throw new Error('404 Page not found');
                } else {
                    throw new Error(`${response.status}`);
                }
            })
            .catch(error => {
                throw new Error(`: ${error.message}`);
            });
    } else if (reqType === 'allPipes') {
        return fetch(`${URL}/api/pipe`, {
            headers: {
                'Authorization': `sessionid ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 404) {
                    throw new Error('404 Page not found');
                } else {
                    throw new Error(`${response.status}`);
                }
            })
            .catch(error => {
                throw new Error(`: ${error.message}`);
            });
    } else if (reqType === 'singleZone') {
        return fetch(`${URL}/api/zone/${id}`, {
            headers: {
                'Authorization': `sessionid ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else if (response.status === 404) {
                    throw new Error('404 Page not found');
                } else {
                    throw new Error(`${response.status}`);
                }
            })
            .catch(error => {
                throw new Error(`: ${error.message}`);
            });
    }
}

export const postField = (fieldInfo, sessionToken) => {

    return fetch(`${URL}/api/hardware/fctl`, {
        method: 'POST', 
        headers: {
            'Authorization': `sessionid ${sessionToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fieldInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            }else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });

};

export const postPipe = (pipeInfo, token) => {

    return fetch(`${URL}/api/pipe`, {
        method: 'POST',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pipeInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const postZone = (zoneInfo, token) => {

    return fetch(`${URL}/api/zone`, {
        method: 'POST',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(zoneInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const postLogin = (loginInfo) => {

    return fetch(`${URL}/login`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}


export const postAccountCreation = (body) => {

    return fetch(`${URL}/register`, {
        method: 'POST', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            }else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });

};

export const fetchFctlHw = (token) => {

    return fetch(`${URL}/api/hardware/fctl`, {
        method: 'GET',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const postPipeSequence = (body, token, id) => {
    // Replace this with the actual token you want to use
   return fetch(`${URL}/api/hardware/fctl/sequence`, {
       method: 'POST', 
       headers: {

           'Authorization': `sessionid ${token}`,
           'Accept': 'application/json',
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(body),
   })
       .then(response => {
           if (response.ok) {
               return response.json()
           }else {
               throw new Error(`${response.status}`);
           }
       })
       .catch(error => {
           throw new Error(`: ${error.message}`);
       });
};

export const postPipePercentage = (body, token) => {
    // Replace this with the actual token you want to use
    console.log("Percentage Request:", body)

   return fetch(`${URL}/api/update-pipe-percent`, {
       method: 'POST', 
       headers: {
           'Authorization': `sessionid ${token}`,
           'Accept': 'application/json',
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(body),
   })
       .then(response => {
           if (response.ok) {
               return response.json()
           }else {
               throw new Error(`${response.status}`);
           }
       })
       .catch(error => {
           throw new Error(`: ${error.message}`);
       });

};


export const postPipeState = (body, token) => {
    // Replace this with the actual token you want to use

   return fetch(`${URL}/api/update-pipe-state`, {
       method: 'POST', 
       headers: {
           'Authorization': `sessionid ${token}`,
           'Accept': 'application/json',
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(body),
   })
       .then(response => {
           if (response.ok) {
               return response.json()
           }else {
               throw new Error(`${response.status}`);
           }
       })
       .catch(error => {
           throw new Error(`: ${error.message}`);
       });

};

export const fetchSinglePipe = (id, token) => {
    return fetch(`${URL}/api/pipe/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const fetchHistory = (token) => {

    return fetch(`${URL}/api/logs`, {
        method: 'GET',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}