import React, { useState } from 'react';
import { changeFieldName } from '../../APICalls/PutRequests';
import { fetchData } from '../../APICalls/GetRequests';
import Cookies from 'js-cookie';

const FieldNamePopup = ({ field, handleFieldNamePopupClose }) => {
    const [newName, setNewName] = useState(''); // Initialize newName state variable with an empty string

    const handleSubmit = (event) => {
        event.preventDefault();
        const prop = { id: field.id, name: newName }
        changeFieldName(prop, Cookies.get('session_key'))
            .then(data => {
                console.log(data)
            })
            .catch(error => {
                console.log(error)
            })
    };

    const handleChange = (event) => {
        setNewName(event.target.value); // Update newName state variable with the value entered into the input field
    };

    return (
        <div className="popup-container">
            <div className="popup">
                <h2>Edit {field.name}'s Name</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="newName">New name:</label>
                    <input
                        type="text"
                        id="newName"
                        name="newName"
                        value={newName} // Bind input value to the newName state variable
                        onChange={handleChange} // Call handleChange function when input value changes
                    />
                    <button type="button" onClick={handleFieldNamePopupClose}>
                        Cancel
                    </button>
                    <button type="submit">Confirm</button>
                </form>
            </div>
        </div>
    );
};

export default FieldNamePopup;