// Import the styling
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ZoneDropdown.scss';

const ZoneDropdown = ({ field, zones, setSelectedZone }) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle clicking on a zone and navigating to its details
  const handleZoneClick = (zone) => {
    setSelectedZone(zone);
    navigate(`../field/${field.name}/zone/${zone.id}`);
  };

  // Function to handle clicks outside the dropdown to close it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Add an event listener to detect clicks outside the dropdown
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    // Cleanup: remove the event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const filteredZones = zones.filter((zone) => {
    return zone.field_id === field.id
  })

  return (
    <div className={`dropdown ${isDropdownOpen ? 'active' : ''}`} ref={dropdownRef}>
      <button className="dropbtn" onClick={toggleDropdown}>
        Select Zone
      </button>
      <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
        {/* Map through zones and create dropdown options */}
        {filteredZones.map((zone, index) => (
          <div
            className="zone-dropdown-option"
            key={index}
            onClick={() => handleZoneClick(zone)}
          >
            {zone.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZoneDropdown;
