import React from 'react';
import './SingleSchedule.scss';
import { addMinutes, addHours, format } from 'date-fns';

const SingleSchedule = ({ selectedSchedule, field }) => {
  // Check if nextStartTime is not empty
  const shouldDisplayTimeColumn = selectedSchedule.nextStartTime !== '';

  // Parse the next start time from the selected schedule
  function parseDateTime(dateTimeString) {
    const [datePart, timePart] = dateTimeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hour, minute);
  }

  let startTime = parseDateTime(selectedSchedule.nextStartTime);

  // Update the start time based on the previous hours and minutes
  function currentTime(prevHours, prevMinutes) {
    if (prevHours) {
      startTime = addHours(startTime, prevHours);
    }
    if (prevMinutes) {
      startTime = addMinutes(startTime, prevMinutes);
    }
  }

  return (
    <div className='SingleSchedule'>
      <h1 className='schedule-name'>{selectedSchedule.scheduleName}</h1>
      <div className='start-and-table-container'>
        <div className='start-time-container'>
          {shouldDisplayTimeColumn && (
            <h3>Next Scheduled Start Time: {format(startTime, 'MM/dd/yyyy h:mm a')}</h3>
          )}
          <button className='manual-start'>Manual Start</button>
        </div>
        <table className='schedule-table'>
          <thead>
            <tr>
              {shouldDisplayTimeColumn && <th className='table-header'>Start Time</th>}
              <th className='table-header'>Duration</th>
              <th className='table-header'>Zone</th>
              <th className='table-header'>Pipes</th>
            </tr>
          </thead>
          <tbody>
            {selectedSchedule.sequence.map((item, index) => {
              // Find the matching zone object by zoneId
              const matchingZone = field.zones.find((zone) => zone.id === item.zoneId);

              // Render pipes associated with the zone
              const pipeList = matchingZone ? matchingZone.pipes.map((pipe, pipeIndex) => (
                <span key={pipeIndex} className='pipe'>
                  Pipe {pipe.pipeNumber} ({pipe.pipeSide})
                </span>
              )) : null;

              // Display the row's information
              const rowInfo = (
                <tr key={index} className='table-row'>
                  {shouldDisplayTimeColumn && (
                    <td className='time'>{format(startTime, 'h:mm a')}</td>
                  )}
                  <td className='duration'>{item.duration.hours}:{item.duration.minutes}</td>
                  <td className='zone'>{item.zone}</td>
                  <td className='pipe-list'>{pipeList}</td>
                </tr>
              );

              // Call currentTime function here to update startTime
              currentTime(item.duration.hours, item.duration.minutes);

              return rowInfo;
            })}
          </tbody>
        </table>
      </div>
      <button className='manual-progress'>Progress to next zone</button>
    </div>
  );
};

export default SingleSchedule;



