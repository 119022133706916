import React from 'react';
import './PipesBar.scss'; // Import the styling

const PipesBar = ({ fieldId , pipes, setPipeId, handlePipePopupOpen}) => {
  // Generate an array of columns based on the number of pipes
  const columns = pipes
  .filter(pipe => pipe.field_id === fieldId)  // Filter pipes by field_id
  .sort((a, b) => a.local_id - b.local_id)    // Sort by local_id in ascending order
  .map((pipe, index) => {
    return (
      <div 
        onClick={() => { setPipeId(pipe.id); handlePipePopupOpen(true); }} 
        key={index} 
        className="pipe-column"
      >
        <div 
          className={`pipe-left ${pipe.left_gate_open ? 'open' : 'closed'}`}
        >
          Pipe {pipe.local_id} Left
        </div>
        <div 
          className={`pipe-right ${pipe.right_gate_open ? 'open' : 'closed'}`}
        >
          Pipe {pipe.local_id} Right
        </div>
      </div>
    );
  });


  return (
    <div className="PipesBar">
      {columns} {/* Render the generated columns */}
    </div>
  );
};

export default PipesBar;
