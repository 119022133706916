// ../CreateAccount/CreateAccount.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateAccount.scss';
import { postAccountCreation } from '../../APICalls/GetRequests';
import Cookies from 'js-cookie';

const CreateAccount = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d).{8,}$/;

    if (!email.match(emailRegex)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    if (!password.match(passwordRegex)) {
      setErrorMessage('Password must be 8 or more characters, use at least one special character, and a number.');
      return;
    }
    if (password !== passwordConfirm) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setErrorMessage('');
    const body = { username, password, email, access: authKey };
    
    postAccountCreation(body)
      .then(data => {
        if (data.session_key) {
          Cookies.set('session_key', data.session_key, { expires: 7, secure: true });
          Cookies.set('user_id', data.user_id, { expires: 7, secure: true });
          setIsLoggedIn(true);
          navigate('/'); // Navigate to the home page or dashboard after account creation
        }
      })
      .catch(error => {
        console.log("Error", error);
        setErrorMessage('Account creation failed. Please try again.');
      });
  };

  return (
    <div className='CreateAccount'>
      <div className='create-account-box'>
        <h2>Create Account</h2>
        <form className='create-account-form' onSubmit={handleFormSubmit}>
          <div className='form-group'>
            <label className='create-account-label' htmlFor='authKey'>Authentication Key</label>
            <input
              className='create-account-input'
              type='text'
              id='authKey'
              placeholder='Enter your Key'
              value={authKey}
              onChange={(e) => setAuthKey(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label className='create-account-label' htmlFor='email'>Email</label>
            <input
              className='create-account-input'
              type='email'
              id='email'
              placeholder='Enter your email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label className='create-account-label' htmlFor='username'>Username</label>
            <input
              className='create-account-input'
              type='text'
              id='username'
              placeholder='Enter your username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label className='create-account-label' htmlFor='password'>Password</label>
            <input
              className='create-account-input'
              type='password'
              id='password'
              placeholder='Enter your password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className='password-hint'>Password must be 8 or more characters, use at least one special character, and a number.</p>
          </div>
          <div className='form-group'>
            <label className='create-account-label' htmlFor='passwordConfirm'>Confirm Password</label>
            <input
              className='create-account-input'
              type='password'
              id='passwordConfirm'
              placeholder='Re-enter your password'
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
          {errorMessage && <p className='error-message'>{errorMessage}</p>}
          <div className='button-container'>
            <button className='submit' type='submit'>Continue</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
