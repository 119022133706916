import './Main.scss'; // Import the styling
// React Imports
import { Outlet } from 'react-router-dom'; // Import Outlet from 'react-router-dom'

import TopNav from '../TopNav/TopNav'; // Import the TopNav component
import SideNav from '../SideNav/SideNav'; // Import the SideNav component

// Main component that displays the top navigation, side navigation, and content outlet
const Main = ({ setIsLoggedIn, setSelectedField, sideNavFields }) => {
    return (
        <div className='Main'>
            <TopNav setIsLoggedIn={setIsLoggedIn}/> {/* Render the TopNav component */}
            <div className='nav-and-content'>
                <SideNav setSelectedField={setSelectedField} sideNavFields={sideNavFields} /> {/* Render the SideNav component */}
                <div className='content'>
                    <Outlet /> {/* Render the content based on the active route */}
                </div>
            </div>
        </div>
    );
};

export default Main;

