import { URL } from "./URLVariables";

export const changeFieldName = (fieldInfo, token) => {

    return fetch(`${URL}/api/field`, {
        method: 'PUT',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fieldInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const changePipeName = (pipeInfo, token) => {

    return fetch(`${URL}/api/pipe`, {
        method: 'PUT',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pipeInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const changeZoneName = (zoneInfo) => {
    const token = "MjhjMTgwMmYtNGIyMC00MDFiLThhNzUtM2JiZDI0ZDM3NTRk"; // Replace this with the actual token you want to use

    return fetch(`${URL}/api/zone`, {
        method: 'PUT',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(zoneInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}