import React from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './TopNav.css';

const TopNav = ({ setIsLoggedIn }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove the cookies
        Cookies.remove('session_key');
        Cookies.remove('user_id');
        // Set the logged-in state to false
        setIsLoggedIn(false);
        // Navigate to the login page
        navigate('/login');
    };

    return (
        <div className='TopNav'>
            <h1 className='sungate-logo'>Sungate Systems</h1>
            <button className='logout-button' onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default TopNav;