import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../../APICalls/GetRequests';
import './SingleField.scss'; // Import the styling
import Cookies from 'js-cookie';

import ConfigurePopup from '../ConfigurePopup/ConfigurePopup'; // Import the ConfigurePopup component
import PipesBar from '../PipesBar/PipesBar'; // Import the PipesBar component
import AddZonesPopup from '../AddZonesPopup/AddZonesPopup'; // Import the AddZonesPopup component
import ZoneDropdown from '../ZoneDropdown/ZoneDropdown'; // Import the ZoneDropdown component
import ScheduleDropdown from '../ScheduleDropdown/ScheduleDropdown';
import DeleteFieldPopup from '../DeleteFieldPopup/DeleteFieldPopup';
import SinglePipePopup from '../SinglePipePopup/SinglePipePopup';
import FieldNamePopup from '../FieldNamePopup/FieldNamePopup';


const SingleField = ({ field, handleNumOfPipesUpdate, setSelectedZone, setSelectedSchedule, zones, setZones }) => {

  const [isloading, setIsLoading] = useState(true)
  const [selectedField, setSelectedField] = useState({})
  // State for showing the ConfigurePopup
  const [showConfigurePopup, setShowConfigurePopup] = useState(false);
  // State for storing the number of pipes
  const [numOfPipes, setNumOfPipes] = useState(0);
  // State for showing the AddZonesPopup
  const [showAddZonesPopup, setShowAddZonesPopup] = useState(false);

  const [pipes, setPipes] = useState([])

  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const [showPipePopup, setShowPipePopup] = useState(false)

  const [showFieldNamePopup, setShowFieldNamePupup] = useState(false)

  const [pipeId, setPipeId] = useState(null)

  // Set initial values for numOfPipes and zones when field changes
  useEffect(() => {
    if (field) {
      setNumOfPipes(field.numOfPipes);
      setZones(field.zones || []);
    }
  }, [field]);

  useEffect(() => {
    fetchData("singleField", Cookies.get('session_key'), field.id)
      .then(data => {
        setSelectedField(data)
      })
      .catch(error => {
        console.log(error)
      })
    fetchData("allPipes", Cookies.get('session_key') )
      .then(data => {
        setPipes(data)
      })
      .catch(error => {
        console.log( error)
      })
    fetchData("allZones", Cookies.get('session_key'))
      .then(data => {
        setZones(data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [field.id, setZones])

  const refreshPipes = () => {
    fetchData("allPipes", Cookies.get('session_key') )
      .then(data => {
        setPipes(data)
        console.log('refreshed pipes')
      })
      .catch(error => {
        console.log( error)
      })
  }

  // Handle click on the "Sequence (Mobile Only)" button
  const handleConfigureButtonClick = () => {
    setShowConfigurePopup(true);
  };

  const handleFieldPopupClick = () => {
    setShowFieldNamePupup(true)
  }


  // Handle click on the "Add Zone" button
  const handleAddZonesButtonClick = () => {
    setShowAddZonesPopup(true);
  };

  const handleDeleteFieldButtonClick = () => {
    setShowDeletePopup(true)
  }

  const handlePipePopupOpen = () => {
    setShowPipePopup(true);
  };


  // Handle close of the ConfigurePopup
  const handleClosePopup = () => {
    setShowConfigurePopup(false);
  };

  // Handle close of the AddZonesPopup
  const handleCloseAddZonesPopup = () => {
    setShowAddZonesPopup(false);
  };

  const handleCloseDeleteField = () => {
    setShowDeletePopup(false)
  }

  const handlePipePopupClose = () => {
    setShowPipePopup(false)
  }

  const handleFieldNamePopupClose = () => {
    setShowFieldNamePupup(false)
  }

  // Handle change in the number of pipes
  const handleNumOfPipesChange = (newNumOfPipes) => {
    setNumOfPipes(newNumOfPipes);
    handleNumOfPipesUpdate(field.controllerId, newNumOfPipes);
  };

  if (isloading) {
    return (
      <h1>Loading</h1>
    )
  }


  return (
    <div className="SingleField">
      {/* Display the field's name and controller ID */}
      <div onClick={handleFieldPopupClick} className="field-name-container">
        <h2 className='field-name'>{selectedField.name}</h2>
        {/* <p className='controller-id'>{field.controllerId}</p> */}
      </div>
      {/* Display the ZoneDropdown component */}
      <div className='dropdowns-container'>
        <ZoneDropdown zones={zones} field={field} setSelectedZone={setSelectedZone} />
        {/* <ScheduleDropdown setSelectedSchedule={setSelectedSchedule} field={field}/> */}
      </div>
      {/* Display the PipesBar component */}
      <PipesBar fieldId={field.id} pipes={pipes} setPipeId={setPipeId} handlePipePopupOpen={handlePipePopupOpen}/>
      {/* Display buttons for configuration, adding zones, and deleting field */}
      <div className="singlefield-button-container">
        <button className="configure-button" onClick={handleConfigureButtonClick}>Sequence (Mobile Only)</button>
        {/* Display the "Add Zone" button if there are pipes */}
        {pipes.length > 0 && (
          <button className="add-zone-button" onClick={handleAddZonesButtonClick}>Add Zone</button>
        )}
        <Link className='add-zone-button' to={`addSchedule`}>
          Add Schedule
        </Link>
        <button className="delete-field-button" onClick={handleDeleteFieldButtonClick}>Delete Field</button>
      </div>
      {/* Display the ConfigurePopup if showConfigurePopup is true */}
      {showConfigurePopup && (
        <ConfigurePopup
          field={selectedField}
          handleClosePopup={handleClosePopup}
          handleNumOfPipesChange={handleNumOfPipesChange}
          setPipes={setPipes}
        />
      )}
      {/* Display the AddZonesPopup if showAddZonesPopup is true */}
      {showAddZonesPopup && (
        <AddZonesPopup
          setZones={setZones}
          field={field}
          pipes={pipes}
          handleCloseAddZonesPopup={handleCloseAddZonesPopup}
        />
      )}
      {showDeletePopup && (
        <DeleteFieldPopup
          field={field}
          handleCloseDeleteField={handleCloseDeleteField}
        />
      )}
      {showPipePopup && (
        <SinglePipePopup
          field={selectedField}
          pipes={pipes}
          pipeId={pipeId}
          handlePipePopupClose={handlePipePopupClose}
          setPipes={setPipes}
          refreshPipes={refreshPipes}
        />
      )}
      {showFieldNamePopup && (
        <FieldNamePopup 
          field={field}
          handleFieldNamePopupClose={handleFieldNamePopupClose}
        />
      )}
    </div>
  );
};


export default SingleField;
