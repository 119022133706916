import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from 'react-router-dom'
import './Login.scss'; // Import the styling
import { postLogin } from '../../APICalls/GetRequests';
import Cookies from 'js-cookie';

const LogIn = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate here

  // Function to handle the login form submission
  const handleLogin = async (event) => {
    event.preventDefault();
    const body = { username, password };
    try {
      const data = await postLogin(body);
      // Set cookies with proper attributes
      Cookies.set('session_key', data.session_key);
      Cookies.set('user_id', data.user_id);
  
      // Log the cookie value after a short delay
      setTimeout(() => {
        console.log("SESSION TOKEN COOKIE", Cookies.get('session_key'));
      }, 500);
  
      setIsLoggedIn(true);
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
    }
  };
  

  return (
    <div className='LogIn'>
      <div className='login-box'>
        <h2>Login</h2>
        <form className='login-form' onSubmit={handleLogin}>
          <div className='form-group'>
            <label className='login-label' htmlFor='username'>
              Username
            </label>
            <input
              className='login-input'
              type='text'
              id='username'
              placeholder='Enter your username'
              onChange={(e) => setUsername(e.target.value)} // Update the username state on input change
            />
          </div>
          <div className='form-group'>
            <label className='login-label' htmlFor='password'>
              Password
            </label>
            <input
              className='login-input'
              type='password'
              id='password'
              placeholder='Enter your password'
              onChange={(e) => setPassword(e.target.value)} // Update the password state on input change
            />
          </div>
          <div className='button-container'>
            <button className='submit' type='submit'>
              Submit
            </button>
            <div className='links'>
              <span className='link' onClick={() => navigate('/create-account')}>Create an Account</span> {/* Update to navigate to create account */}
              <span className='forgot-password link'>Forgot Password?</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
