import { URL } from "./URLVariables";

export const deleteField = (fieldInfo, token, id) => {

    return fetch(`${URL}}/api/field`, {
        method: 'DELETE',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fieldInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const deletePipe = (pipeInfo, token) => {

    return fetch(`${URL}/api/pipe`, {
        method: 'DELETE',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pipeInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}

export const deleteZone = (zoneInfo, token) => {

    return fetch(`${URL}/api/zone`, {
        method: 'DELETE',
        headers: {
            'Authorization': `sessionid ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(zoneInfo),
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .catch(error => {
            throw new Error(`: ${error.message}`);
        });
}