import { deleteField } from "../../APICalls/DeleteRequests"
import Cookies from "js-cookie"

const DeleteFieldPopup = ({ field, handleCloseDeleteField }) => {

    const cutField = () => {
        const fieldObj = { id: field.id }
        deleteField(fieldObj, Cookies.get('session_key'))
            .then(data => {
                console.log(data)
            })
            .catch(error => {
                console.log(error)
            })
    }


    return (
        <div className="popup-container">
            <div className="popup">
                <h2>Warning</h2>
                <p>You are about to delete all field data for {field.name}, click the  confirm deletion button to continue</p>
                <button onClick={handleCloseDeleteField}>Cancel</button>
                <button onClick={cutField}>Confirm</button>
            </div>
        </div>
    )
}

export default DeleteFieldPopup