import React, { useState } from 'react';
import { useEffect } from 'react';
import './AddZonesPopup.scss'; // Import the styling
import Cookies from 'js-cookie';

import { postZone } from '../../APICalls/GetRequests';
import { fetchData } from '../../APICalls/GetRequests';

// Function to generate a unique ID
const generateUniqueId = () => {
  return new Date().getTime().toString(36) + Math.random().toString(36).slice(2);
};

const AddZonesPopup = ({ field, pipes, handleCloseAddZonesPopup, setZones }) => {
  const [selectedPipeType, setSelectedPipeType] = useState('pipe 1 left');
  const [selectedPipesDisplay, setSelectedPipesDisplay] = useState([]);
  const [zoneName, setZoneName] = useState('');
  const [availablePipeOptions, setAvailablePipeOptions] = useState([]);
  const [isNameEntered, setIsNameEntered] = useState(false);

  const filteredPipes = pipes.filter(pipe => {
    if (pipe.field === field.id) {
      return pipe
    }
  })

  useEffect(() => {
    const initialPipeOptions = [];
    for (let i = 1; i <= filteredPipes.length; i++) {
      initialPipeOptions.push(`pipe ${i} left`);
      initialPipeOptions.push(`pipe ${i} right`);
    }
    setAvailablePipeOptions(initialPipeOptions);
  }, [pipes]);

  const handleZonesSubmission = (newZone) => {
  
    postZone(newZone, Cookies.get('session_key'))
      .then(data => {
        fetchData("allZones", Cookies.get('session_key'))
          .then(data => {
            setZones(data)
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
    // handleZonesUpdate(field.controllerId, newZone);
  };

  // Function to handle the "Add" button click
  const handleAddButtonClick = () => {

    const [pipe, pipeNumber, pipeSide] = selectedPipeType.split(' ');
    setSelectedPipesDisplay((prevSelectedPipesDisplay) => [
      ...prevSelectedPipesDisplay,
      { pipe, pipeNumber, pipeSide } // Store pipe attributes as an object
    ]);

    const filteredArray = availablePipeOptions.filter((option) => option !== selectedPipeType);
    // Remove the selected option from available options
    setAvailablePipeOptions(filteredArray);

    // Clear the selection
    setSelectedPipeType(filteredArray[0]);
  };

  // Function to handle the "Add Zones" button click
  const handleAddZonesClick = () => {
    const pipesArray = selectedPipesDisplay.map((pipeObj, index) => ({
      key: index, // Assign a unique key starting from 0
      pipeNumber: pipeObj.pipeNumber,
      pipeSide: pipeObj.pipeSide,
      pipeComboName: '',
      state: false // Initialize the state as off
    }));

    const newZone = {
      name: zoneName,
      field_id: field.id,
      // pipes: pipesArray
    };

    handleZonesSubmission(newZone);
    handleCloseAddZonesPopup();
  };

  

  const pipeOptions = [];

  // Generate pipe options based on the number of pipes
  for (let i = 1; i <= pipes.length; i++) {
    pipeOptions.push(
      <option key={`pipe-${i}-left`} value={`pipe ${i} left`}>
        Pipe {i} Left
      </option>
    );
    pipeOptions.push(
      <option key={`pipe-${i}-right`} value={`pipe ${i} right`}>
        Pipe {i} Right
      </option>
    );
  }

  // Function to handle the change in selected pipe type
  const handlePipeSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedPipeType(selectedOption);

    // Remove the selected option from available options
  };

  // Function to handle the change in zone name
  const handleZoneNameChange = (event) => {
    const name = event.target.value;
    setZoneName(name);
    setIsNameEntered(!!name); // Set isNameEntered to true if name is not empty
  };


  return (
    <div className="AddZonesPopup">
      <div className='popup'>
        <h3>Add Pipes to Zone</h3>
        <div className="form-group">
          <label>Select Pipe:</label>
          <select value={selectedPipeType} onChange={handlePipeSelectChange}>
            {availablePipeOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={handleAddButtonClick}>Add</button>
        </div>
        {selectedPipesDisplay.length > 0 && (
          <div className="selected-pipes-container">
            {selectedPipesDisplay.map((pipe, index) => (
              <div key={`selected-pipe-${index}`} className="selected-pipe">
                {pipe.pipe} {pipe.pipeNumber} {pipe.pipeSide}
              </div>
            ))}
          </div>
        )}
        <div className="form-group">
          <label>Zone Name:</label>
          <input type="text" value={zoneName} onChange={handleZoneNameChange} />
        </div>
        <div className="button-container">
          <button className="cancel-button" onClick={handleCloseAddZonesPopup}>
            Cancel
          </button>
          <button
            className="add-button"
            onClick={handleAddZonesClick}
            disabled={!isNameEntered} // Disable the button when name is not entered
          >
            Add Zone
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddZonesPopup;


