import './History.scss';
import { fetchHistory } from '../../APICalls/GetRequests';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const History = () => {
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        fetchHistory(Cookies.get('session_key'))
            .then(data => {
                setHistoryData(data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <div className="history-container">
            <h1>History</h1>
            {historyData.length > 0 ? (
                <ul className="history-list">
                    {historyData.slice().reverse().map(entry => ( // reverse the data array
                        <li key={entry.id} className="history-item">
                            <div className="history-date">{new Date(entry.timedate).toLocaleString()}</div>
                            <div className="history-content">{entry.content}</div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No history available.</p>
            )}
        </div>
    );
};

export default History;

