import React, { useEffect, useState } from 'react';
import { deletePipe } from "../../APICalls/DeleteRequests";
import { fetchData } from "../../APICalls/GetRequests";
import { changePipeName } from '../../APICalls/PutRequests';
import Cookies from 'js-cookie';
import { postPipePercentage, postPipeState, fetchSinglePipe } from '../../APICalls/GetRequests';
import './SinglePipePopup.scss';

const SinglePipePopup = ({ field, pipes, pipeId, handlePipePopupClose, setPipes, refreshPipes }) => {
    const [selectedPipe] = pipes.filter(pipe => pipe.id === pipeId);
    const [showRenameForm, setShowRenameForm] = useState(false);
    const [newPipeName, setNewPipeName] = useState(selectedPipe.name);
    const [leftOpenPercent, setLeftOpenPercent] = useState(null);
    const [rightOpenPercent, setRightOpenPercent] = useState(null);
    const [serverLeftOpenPercent, setServerLeftOpenPercent] = useState(null);
    const [serverRightOpenPercent, setServerRightOpenPercent] = useState(null);
    const [percentageCommand, setPercentageCommand] = useState({
        pipe_id: selectedPipe.id.toString(),
        left_open_percent: null,
        right_open_percent: null
    });
    const [stateCommand, setStateCommand] = useState({
        pipe_id: selectedPipe.id.toString(),
        account_id: selectedPipe.account_id,
        field_id: selectedPipe.field_id,
        left_gate_open: null,
        right_gate_open: null
    });
    const [pipeState, setPipeState] = useState({
        left_gate_open: false,
        right_gate_open: false
    });

    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        fetchSinglePipe(selectedPipe.id, Cookies.get('session_key'))
            .then(data => {
                setPipeState({
                    left_gate_open: data.left_gate_open,
                    right_gate_open: data.right_gate_open
                });
                setServerLeftOpenPercent(data.left_open_percent);
                setServerRightOpenPercent(data.right_open_percent);
            })
            .catch(error => {
                console.log(error);
            });
    }, [selectedPipe.id]);

    const toggleRenameForm = () => {
        setShowRenameForm(!showRenameForm);
    };

    const handleInputChange = (e) => {
        setNewPipeName(e.target.value);
    };

    const updatePipeName = () => {
        setLoading(true); // Start loading
        changePipeName({ id: pipeId, field_id: field.id, name: newPipeName }, Cookies.get('session_key'))
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoading(false)); // End loading
    };

    const handleLeftOpenPercentChange = (e) => {
        const value = Number(e.target.value);
        if (value > 0 && value <= 100) {
            setLeftOpenPercent(value);
            setPercentageCommand(prevState => ({
                ...prevState,
                left_open_percent: value
            }));
        } else {
            setLeftOpenPercent(null);
            setPercentageCommand(prevState => ({
                ...prevState,
                left_open_percent: null
            }));
        }
    };

    const handleRightOpenPercentChange = (e) => {
        const value = Number(e.target.value);
        if (value > 0 && value <= 100) {
            setRightOpenPercent(value);
            setPercentageCommand(prevState => ({
                ...prevState,
                right_open_percent: value
            }));
        } else {
            setRightOpenPercent(null);
            setPercentageCommand(prevState => ({
                ...prevState,
                right_open_percent: null
            }));
        }
    };

    const updateLeftOpenPercent = () => {
        setLoading(true); // Start loading
        const updatedCommand = {
            ...percentageCommand,
            left_open_percent: leftOpenPercent, // Only update left percent
            right_open_percent: null // Ensure right percent is not updated
        };
        postPipePercentage(updatedCommand, Cookies.get('session_key'))
            .then(data => {
                fetchAndUpdatePipeState()
                console.log(data);
                setLeftOpenPercent(null)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoading(false)); // End loading
    };
    
    const updateRightOpenPercent = () => {
        setLoading(true); // Start loading
        const updatedCommand = {
            ...percentageCommand,
            right_open_percent: rightOpenPercent, // Only update right percent
            left_open_percent: null // Ensure left percent is not updated
        };
        postPipePercentage(updatedCommand, Cookies.get('session_key'))
            .then(data => {
                fetchAndUpdatePipeState()
                console.log(data);
                setRightOpenPercent(null)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoading(false)); // End loading
    };
    

    const fetchAndUpdatePipeState = () => {
        fetchSinglePipe(selectedPipe.id, Cookies.get('session_key'))
            .then(data => {
                setPipeState({
                    left_gate_open: data.left_gate_open,
                    right_gate_open: data.right_gate_open,
                });
                setServerLeftOpenPercent(data.left_open_percent)
                setServerRightOpenPercent(data.right_open_percent)
            })
            .catch(error => {
                console.log(error);
            });
    };

    const toggleLeftGate = () => {
        const isCurrentlyOpen = pipeState.left_gate_open;
        const updatedStateCommand = {
            ...stateCommand,
            left_gate_open: !isCurrentlyOpen,
            right_gate_open: null
        };
        setStateCommand(updatedStateCommand);

        setLoading(true); // Start loading
        postPipeState(updatedStateCommand, Cookies.get('session_key'))
            .then(data => {
                console.log(data);
                fetchAndUpdatePipeState();
                refreshPipes()
            })
            .catch(error => {
                console.log(error);
                fetchAndUpdatePipeState();
                refreshPipes()
            })
            .finally(() => setLoading(false)); // End loading
    };

    const toggleRightGate = () => {
        const isCurrentlyOpen = pipeState.right_gate_open;
        const updatedStateCommand = {
            ...stateCommand,
            left_gate_open: null,
            right_gate_open: !isCurrentlyOpen
        };
        setStateCommand(updatedStateCommand);

        setLoading(true); // Start loading
        postPipeState(updatedStateCommand, Cookies.get('session_key'))
            .then(data => {
                console.log(data);
                fetchAndUpdatePipeState();
                refreshPipes()
            })
            .catch(error => {
                console.log(error);
                fetchAndUpdatePipeState();
                refreshPipes()
            })
            .finally(() => setLoading(false)); // End loading
    };

    const removePipe = () => {
        const parameter = { id: selectedPipe.id };
        setLoading(true); // Start loading
        deletePipe(parameter, Cookies.get('session_key'))
            .then(data => {
                fetchData("allPipes")
                    .then(data => {
                        handlePipePopupClose();
                        setPipes(data);
                    })
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoading(false)); // End loading
    };

    return (
        <div className="popup-container">
            <div className="popup">
                <h1 className="pipe-name">{selectedPipe.name}</h1>
                {showRenameForm ? (
                    <div className="rename-form">
                        <input
                            type="text"
                            value={newPipeName}
                            onChange={handleInputChange}
                            placeholder="Enter new pipe name"
                            className="rename-input"
                            disabled={loading} // Disable input when loading
                        />
                        <button onClick={updatePipeName} className="update-button" disabled={loading}>
                            Update Pipe Name
                        </button>
                    </div>
                ) : (
                    <button onClick={toggleRenameForm} className="rename-button" disabled={loading}>
                        Rename Pipe
                    </button>
                )}
                <div className="left-right-section-container">
                    <div className="left-section">
                        <h2 className="percentage-title">Left Open Percent: {serverLeftOpenPercent}</h2>
                        <h3 className="update-title">Update Left Open Percentage</h3>
                        <input
                            type="number"
                            value={leftOpenPercent === null ? '' : leftOpenPercent}
                            onChange={handleLeftOpenPercentChange}
                            className="percentage-input"
                            disabled={loading} // Disable input when loading
                            min="1" max="100"
                        />
                        <button onClick={updateLeftOpenPercent} className="update-button" disabled={loading || leftOpenPercent === null}>
                            Update Left
                        </button>
                        <div className="gate-toggle">
                            <h3 className="toggle-title">Toggle Left Gate</h3>
                            <button 
                                onClick={toggleLeftGate} 
                                className={`toggle-button ${pipeState.left_gate_open ? 'close-gate' : 'open-gate'}`} 
                                disabled={loading}
                            >
                                {pipeState.left_gate_open ? 'Close Left Gate' : 'Open Left Gate'}
                            </button>
                        </div>
                        {/* Display error message for left gate */}
                        {selectedPipe.left_gate_last_result !== 0 && (
                            <p className="error-message">
                                Error on last action, see history tab
                            </p>
                        )}
                    </div>
                    <div className="right-section">
                        <h2 className="percentage-title">Right Open Percent: {serverRightOpenPercent}</h2>
                        <h3 className="update-title">Update Right Open Percentage</h3>
                        <input
                            type="number"
                            value={rightOpenPercent === null ? '' : rightOpenPercent}
                            onChange={handleRightOpenPercentChange}
                            className="percentage-input"
                            disabled={loading} // Disable input when loading
                            min="1" max="100"
                        />
                        <button onClick={updateRightOpenPercent} className="update-button" disabled={loading || rightOpenPercent === null}>
                            Update Right
                        </button>
                        <div className="gate-toggle">
                            <h3 className="toggle-title">Toggle Right Gate</h3>
                            <button 
                                onClick={toggleRightGate} 
                                className={`toggle-button ${pipeState.right_gate_open ? 'close-gate' : 'open-gate'}`} 
                                disabled={loading}
                            >
                                {pipeState.right_gate_open ? 'Close Right Gate' : 'Open Right Gate'}
                            </button>
                        </div>
                        {/* Display error message for right gate */}
                        {selectedPipe.right_gate_last_result !== 0 && (
                            <p className="error-message">
                                Error on last action, see history tab
                            </p>
                        )}
                    </div>
                </div>
                {/* <button onClick={removePipe} className="delete-button" disabled={loading}>
                    Delete Pipe
                </button> */}
                <button onClick={handlePipePopupClose} className="close-button" >
                    Close
                </button>
            </div>
        </div>
    );
};

export default SinglePipePopup;
