import { useState } from "react";
import { changeZoneName } from "../../APICalls/PutRequests";

const ZoneNamePopup = ({selectedField, closeZoneNamePopup, selectedZone}) => {
    const [newName, setNewName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const prop = { field: selectedField.id, id:selectedZone.id, name: newName }
        changeZoneName(prop)
            .then(data => {
                console.log(data)
            })
            .catch(error => {
                console.log(error)
            })
    };


    const handleChange = (event) => {
        setNewName(event.target.value); // Update newName state variable with the value entered into the input field
    };

    return (
        <div className="popup-container">
            <div className="popup">
                <h2>Edit Name</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="newName">New name:</label>
                    <input
                        type="text"
                        id="newName"
                        name="newName"
                        value={newName} // Bind input value to the newName state variable
                        onChange={handleChange} // Call handleChange function when input value changes
                    />
                    <button type="button" onClick={closeZoneNamePopup}>
                        Cancel
                    </button>
                    <button type="submit">Confirm</button>
                </form>
            </div>
        </div>
    );
}

export default ZoneNamePopup