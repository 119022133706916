import './SideNav.scss'; // Import the styling
//Material UI imports
import * as React from 'react';
import List from '@mui/material/List'; // Import List component from Material-UI
import ListItem from '@mui/material/ListItem'; // Import ListItem component from Material-UI
import ListItemButton from '@mui/material/ListItemButton'; // Import ListItemButton component from Material-UI
import ListItemIcon from '@mui/material/ListItemIcon'; // Import ListItemIcon component from Material-UI
import ListItemText from '@mui/material/ListItemText'; // Import ListItemText component from Material-UI
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Import AccessTimeIcon from Material-UI icons
import HistoryIcon from '@mui/icons-material/History'; // Import HistoryIcon from Material-UI icons
import GrassIcon from '@mui/icons-material/Grass'; // Import GrassIcon from Material-UI icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Import AddCircleOutlineIcon from Material-UI icons
import Collapse from '@mui/material/Collapse'; // Import Collapse component from Material-UI
import { ExpandLess } from '@mui/icons-material'; // Import ExpandLess icon from Material-UI icons
import { ExpandMore } from '@mui/icons-material'; // Import ExpandMore icon from Material-UI icons
import CropSquareIcon from '@mui/icons-material/CropSquare'; // Import CropSquareIcon from Material-UI icons

//React Imports
import { Link } from 'react-router-dom'; // Import Link component from react-router-dom
import { useState} from 'react';

const SideNav = ({ setSelectedField, sideNavFields }) => {
  const [open, setOpen] = useState(true);

  // Handle click on a field to expand/collapse the field's options
  const handleClick = (field) => {
    setSelectedField(field); // Set the selected field
    setOpen(!open); // Toggle the open state
  };

  return (
    <div className="list-container">
      <List className="listcomponent">
        {/* Add Field */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/home/addfield">
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Add Field" />
          </ListItemButton>
        </ListItem>

        {/* Fields */}
        <ListItem disablePadding onClick={() => setOpen(!open)}>
          <ListItemButton>
            <ListItemIcon>
              <GrassIcon />
            </ListItemIcon>
            <ListItemText primary="Fields" />
          </ListItemButton>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        {/* Field options */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sideNavFields.map((field) => (
              <ListItemButton
                key={field.id}
                onClick={() => handleClick(field)} 
                component={Link}
                to={`/home/${field.id}`}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <CropSquareIcon />
                </ListItemIcon>
                <ListItemText primary={field.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>

        {/* Schedule */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/home/schedule">
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="Schedule" />
          </ListItemButton>
        </ListItem>

        {/* History */}
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/home/history">
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="History" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default SideNav;
