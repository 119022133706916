import './AddField.scss';
import { useState } from 'react';
import { postField } from '../../APICalls/GetRequests';
import Cookies from 'js-cookie';
import { fetchData } from '../../APICalls/GetRequests';

const AddField = ({ setSideNavFields }) => {
    const initialFormData = {
        housing_id: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const createField = () => {
        postField(formData, Cookies.get('session_key'))
            .then(data => {
                fetchData('fieldnames', Cookies.get('session_key'))
                    .then(data => {
                        setSideNavFields(data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createField();
        setFormData(initialFormData); // Reset the form fields
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <form className='AddField' onSubmit={handleSubmit}>
            <h1>Add Field</h1>
            {/* <div className="form-group">
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </div> */}
            <div className="form-group">
                <label>Housing ID:</label>
                <input type="text" name="housing_id" value={formData.housing_id} onChange={handleChange} />
            </div>
            {/* <div className="form-group">
                <label>Notification (Email or Phone #):</label>
                <input type="text" name="notification" value={formData.notification} onChange={handleChange} />
            </div> */}
            <button type="submit">Submit</button>
        </form>
    );
};

export default AddField;
