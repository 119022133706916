import React, { useEffect, useState } from 'react';
import './ConfigurePopup.scss'; // Import the styling
import {fetchFctlHw, postPipeSequence } from '../../APICalls/GetRequests';
import Cookies from 'js-cookie';

const ConfigurePopup = ({ field, handleClosePopup, setPipes }) => {
  const [fctlList, setFctlList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [pipeArray, setPipeArray] = useState([]);
  const [selectedLocalId, setSelectedLocalId] = useState('');

  useEffect(() => {
    fetchFctlHw(Cookies.get('session_key'))
      .then(data => {
        setFctlList(data);
        const matchingObject = data.find(item => item.id == field.hwid);
        if (matchingObject) {
          setSelectedLocalId(matchingObject.localid);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleAddString = (event) => {
    event.preventDefault();
    if (inputValue.trim()) {
      setPipeArray([...pipeArray, inputValue.trim()]);
      setInputValue(''); // Clear the input field
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    const body = { localid: selectedLocalId, serials: pipeArray };
    postPipeSequence(body, Cookies.get('session_key'), field.Hwid)
      .then(response => {
        console.log('Sequence submitted successfully', response);
        // Handle success response
      })
      .catch(error => {
        console.error('Error submitting sequence', error);
        // Handle error response
      });
  };

  return (
    <div className="popup-container">
      <div className="popup">
        <h2>Sequence {field.name}</h2>
        <p>Start at one end of the irrigation pipeline</p>
        <form onSubmit={handleAddString}>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter string"
          />
          <button type="submit">Add</button>
        </form>
        <ul>
          {pipeArray.map((str, index) => (
            <li key={index}>{str}</li>
          ))}
        </ul>
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={handleClosePopup}>Close</button>
      </div>
    </div>
  );
};

export default ConfigurePopup;
