import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SingleZone.scss';
import EditIcon from '@mui/icons-material/Edit';
import { fetchData } from '../../APICalls/GetRequests';
import { deleteZone } from '../../APICalls/DeleteRequests';
import ZoneNamePopup from '../ZoneNamePopup/ZoneNamePopup';
import Cookies from 'js-cookie';

const SingleZone = ({ selectedField, selectedZone, setSelectedZone, handleZoneUpdate, setZones }) => {

  const [isLoading, setIsLoading] = useState(true)
  const [zonePipes, setZonePipes] = useState([])
  const [zoneName, setZoneName] = useState('')
  const [zoneNamePopup, setZoneNamePopup] = useState(false)
  const navigate = useNavigate();

  // Navigate back to the previous page
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchData("singleZone",Cookies.get('session_key'), selectedZone.id)
      .then(data => {
        setZonePipes(data.pipes)
        setZoneName(data.name)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [selectedZone.id])

  const removeZone = () => {
    const parameter = { id: selectedZone.id }
    deleteZone(parameter, Cookies.get('session_key'))
      .then(data => {
        fetchData("allZones")
          .then(data => {
            setZones(data)
            navigate(-1);
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleZoneNameClick = () => {
    setZoneNamePopup(true)
  }

  const closeZoneNamePopup = () => {
    setZoneNamePopup(false)
  }

  if (isLoading) {
    return (
      <h1>Loading</h1>
    )
  }

  return (
    <div className="SingleZone">
      {/* Display the zone's name */}
      <h2 className="zone-title" onClick={setZoneNamePopup}>Zone: {zoneName}</h2>
      {/* Button to toggle the state of all pipes */}
      <button className="toggle-all-button">
        Toggle All
      </button>
      {/* Button to navigate back */}
      <button className="back-button" onClick={goBack}>
        Back
      </button>
      {/* Display other zone information */}
      <div className="zone-info">
        {/* Display other zone information here */}
      </div>
      {/* Display the list of pipes */}
      <div className="pipe-bars">

      </div>
      <button className='delete-button' onClick={removeZone}>Delete Zone</button>
      <button>Rename Zone</button>
      {zoneNamePopup &&
        <ZoneNamePopup
         selectedField={selectedField}
         closeZoneNamePopup={closeZoneNamePopup}
         selectedZone={selectedZone}
        />
      }
    </div>
  );
};

export default SingleZone;