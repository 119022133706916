import './App.css';

// React Imports
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'; // Import useLocation
import { useNavigate } from 'react-router'; // Importing useNavigate for routing

// Component Imports
import LogIn from '../LogIn/LogIn';
import Main from '../Main/Main';
import AddField from '../AddField/AddField';
import History from '../History/History';
import SingleField from '../SingleField/SingleField';
import SingleZone from '../SingleZone/SingleZone';
import AddSchedule from '../AddSchedule/AddSchedule';
import SingleSchedule from '../SingleSchedule/SingleSchedule';
import CreateAccount from '../CreateAccount/CreateAccount';

import { fetchData } from '../../APICalls/GetRequests';
import Cookies from 'js-cookie'; // Import js-cookie

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status
  const [selectedField, setSelectedField] = useState(null); // State to store selected field data
  const [selectedZone, setSelectedZone] = useState(null); // State to store selected zone data
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [sideNavFields, setSideNavFields] = useState([]);
  const [zones, setZones] = useState([]);
  const navigate = useNavigate(); // Hook to manage navigation within the app
  const location = useLocation(); // Hook to get the current location

  // Use effect to check login status from cookies
  useEffect(() => {
    const sessionKey = Cookies.get('session_key');
    const userId = Cookies.get('user_id');
    const publicPaths = ['/login', '/create-account'];

    if (!sessionKey || !userId) {
      if (!publicPaths.includes(location.pathname)) {
        navigate('/login'); // Navigate to '/login' if not logged in and not on a public path
      }
    } else {
      setIsLoggedIn(true);
    }
  }, [location, navigate]);

  // Fetch data when logged in
  useEffect(() => {
    if (isLoggedIn) {
      fetchData('fieldnames', Cookies.get('session_key'))
        .then(data => {
          setSideNavFields(data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      <Routes>
        {/* Route for the root path */}
        <Route path="/" element={<Navigate to="/home" />} />
        
        <Route path="/login" element={<LogIn setIsLoggedIn={setIsLoggedIn} />} /> {/* Route for the login page */}
        <Route path="/create-account" element={<CreateAccount setIsLoggedIn={setIsLoggedIn} />} /> {/* Route for the create account page */}
        <Route path="/home/*" element={<Main setIsLoggedIn={setIsLoggedIn} sideNavFields={sideNavFields} setSelectedField={setSelectedField} />}>
          <Route path="addfield" element={<AddField setSideNavFields={setSideNavFields} />} />
          <Route path="history" element={<History />} /> {/* Sub-route for history */}
          <Route
            path=":fieldName"
            element={(
              <SingleField
                field={selectedField}
                setSelectedField={setSelectedField}
                setSelectedZone={setSelectedZone}
                setSelectedSchedule={setSelectedSchedule}
                zones={zones}
                setZones={setZones}
              />
            )}
          /> {/* Sub-route for a single field */}
          <Route
            path="field/:fieldName/zone/:zoneId"
            element={<SingleZone selectedField={selectedField} setZones={setZones} selectedZone={selectedZone} setSelectedZone={setSelectedZone} />} /> {/* Sub-route for a single zone */}
          <Route
            path=":fieldName/addSchedule"
            element={<AddSchedule />} /> {/* Sub-route for schedule */}
          <Route
            path="field/:fieldName/:scheduleName"
            element={<SingleSchedule selectedSchedule={selectedSchedule} field={selectedField} />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
